var render = function () {
  var _vm$ultimateParentEnt, _vm$asset2, _vm$account;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('SpinnerLogo', {
    staticClass: "p-5"
  }) : _c('Pages', {
    attrs: {
      "title": "CXG Summary"
    }
  }, [_c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-copyright": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('div', {
    staticClass: "bg-lighter rounded-md mb-4 p-4"
  }, [_c('div', {
    staticClass: "row align-items-middle"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('PageHeader', {
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate, 'MMM YYYY')) + " to " + _vm._s(_vm._f("date")(_vm.period.endDate, 'MMM YYYY')))];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_vm._v("Meter Management")];
      },
      proxy: true
    }, {
      key: "bottom",
      fn: function fn() {
        var _vm$asset;

        return [_vm._v(_vm._s(((_vm$asset = _vm.asset) === null || _vm$asset === void 0 ? void 0 : _vm$asset.siteName) || 'N/A'))];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "asset-info"
  }, [_c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Company"
    }
  }, [_vm._v(_vm._s(((_vm$ultimateParentEnt = _vm.ultimateParentEntity) === null || _vm$ultimateParentEnt === void 0 ? void 0 : _vm$ultimateParentEnt.legalName) || 'N/A'))]), _c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Site"
    }
  }, [_vm._v(_vm._s(((_vm$asset2 = _vm.asset) === null || _vm$asset2 === void 0 ? void 0 : _vm$asset2.siteName) || 'N/A'))]), _c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Meter"
    }
  }, [_vm._v(_vm._s(((_vm$account = _vm.account) === null || _vm$account === void 0 ? void 0 : _vm$account.meterPointNumber) || 'N/A'))])], 1)]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "asset-info"
  }, [_c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Period From"
    }
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate || 'N/A', 'DD MMMM YYYY')))]), _c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Period To"
    }
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.endDate || 'N/A', 'DD MMMM YYYY')))]), _c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Units"
    }
  }, [_vm._v(_vm._s(_vm.friendlyMeterUnit))])], 1)]), _c('div', {
    staticClass: "col-md-2 text-right"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.company.settings.reportLogoUrl || _vm.company.settings.logoUrl
    }
  })])])]), _vm.analytics.consumption.accountId ? _c('div', [_c('h5', {
    staticClass: "border-bottom pb-2"
  }, [_vm._v("Monthly Charges")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('HHCapacityInformation', {
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumption,
      "prev-data": _vm.analytics.consumptionPrevYear
    }
  })], 1), _c('div', {
    staticClass: "col-md-7"
  }, [_c('AccountDailyConsumption', {
    staticClass: "mb-2 border-bottom",
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumption.data,
      "units": _vm.friendlyMeterUnit
    }
  }), _c('AccountHourlyDemand', {
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumption.data,
      "units": _vm.friendlyMeterUnit
    }
  })], 1)])]) : _vm._e()])]), _c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-copyright": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_vm.analytics.consumption.accountId ? _c('div', [_c('h5', {
    staticClass: "border-bottom pb-2"
  }, [_vm._v("Monthly Meter Profile")]), _c('table', {
    staticClass: "calendar w-100 mb-3"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Monday")]), _c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Tuesday")]), _c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Wednesday")]), _c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Thursday")]), _c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Friday")]), _c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Saturday")]), _c('th', {
    attrs: {
      "width": "14.25%"
    }
  }, [_vm._v("Sunday")])])]), _c('tbody', _vm._l(_vm.consumptionCalendarCharts, function (chartRow, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 1;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 1;
        })
      }
    })], 1) : _vm._e()]), _c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 2;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 2;
        })
      }
    })], 1) : _vm._e()]), _c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 3;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 3;
        })
      }
    })], 1) : _vm._e()]), _c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 4;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 4;
        })
      }
    })], 1) : _vm._e()]), _c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 5;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 5;
        })
      }
    })], 1) : _vm._e()]), _c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 6;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 6;
        })
      }
    })], 1) : _vm._e()]), _c('td', [chartRow.some(function (item) {
      return item.weekdayIndex === 0;
    }) ? _c('div', [_c('Chart', {
      staticStyle: {
        "height": "100px"
      },
      attrs: {
        "svg": "",
        "option": chartRow.find(function (item) {
          return item.weekdayIndex === 0;
        })
      }
    })], 1) : _vm._e()])]);
  }), 0)]), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "bg-light rounded-md px-4 py-3"
  }, [_c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-6",
      "title": "Total Consumption"
    }
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.analytics.consumption.totalConsumption, 2))), _c('span', {
    staticClass: "text-muted small ml-1"
  }, [_vm._v(_vm._s(_vm.friendlyMeterUnit))])]), _c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-6",
      "title": "This month daily average"
    }
  }, [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.currentMonthDailyAverage, 2))), _c('span', {
    staticClass: "text-muted small ml-1"
  }, [_vm._v(_vm._s(_vm.friendlyMeterUnit))]), _c('PctDiff', {
    staticClass: "ml-2",
    attrs: {
      "value": _vm.currentMonthDailyAverage,
      "prev-value": _vm.lastMonthDailyAverage,
      "comparison-period": "year",
      "hide-text": "",
      "precision": "2"
    }
  })], 1)]), _c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-6",
      "title": "Last month daily average"
    }
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.lastMonthDailyAverage, 2))), _c('span', {
    staticClass: "text-muted small ml-1"
  }, [_vm._v(_vm._s(_vm.friendlyMeterUnit))])]), _vm.peakConsumptionItem ? _c('PropertyListItem', {
    staticClass: "font-w600",
    attrs: {
      "col-size": "col-md-6",
      "title": "Peak consumption"
    }
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.peakConsumptionItem.consumption, 2))), _c('span', {
    staticClass: "text-muted small ml-1"
  }, [_vm._v(_vm._s(_vm.friendlyMeterUnit))]), _vm._v(" (" + _vm._s(_vm._f("date")(_vm.peakConsumptionItem.date, 'DD MMMM YYYY HH:mm')) + ")")]) : _vm._e()], 1)])])]) : _vm._e()])]), _c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-copyright": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Month")]), _c('th', [_vm._v("Consumption")]), _c('th', [_vm._v("Cost")]), _c('th', [_vm._v("tCO2")])])]), _c('tbody', [_vm._l(_vm.monthlySummaryData, function (monthData) {
    return _c('tr', {
      key: monthData.date
    }, [_c('td', [_vm._v(_vm._s(_vm._f("date")(monthData.date, 'MMMM')))]), _c('td', {
      staticClass: "text-right"
    }, [monthData.consumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.consumption, 0)))]) : _c('span', [_vm._v("N/A")])]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v("£" + _vm._s(_vm._f("numberFormat")(monthData.cost, 2)))]), _c('td', {
      staticClass: "text-right"
    }, [monthData.emissions !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.emissions, 2)))]) : _c('span', [_vm._v("N/A")])])]);
  }), _c('tr', [_c('th', [_vm._v("Total")]), _c('th', {
    staticClass: "text-right"
  }, [_vm.analytics.consumptionYearly.totalConsumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics.consumptionYearly.totalConsumption, 2)))]) : _c('span', [_vm._v("N/A")])]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("£" + _vm._s(_vm._f("numberFormat")(_vm.monthlySummaryData.reduce(function (acc, item) {
    return acc + item.cost;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  }, [_vm.analytics.consumptionYearly.totalEmissions.total !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics.consumptionYearly.totalEmissions.total / 1000, 2)))]) : _c('span', [_vm._v("N/A")])])])], 2)])]), _c('div', {
    staticClass: "col-md-7"
  }, [_c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "svg": "",
      "option": _vm.yearlyConsumptionChart
    }
  }), _c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "svg": "",
      "option": _vm.yearlyCostChart
    }
  })], 1)])])]), _c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-copyright": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "svg": "",
      "option": _vm.yearlyComparisonChart
    }
  }), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-10"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("Month")]), _c('th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Consumption")]), _c('th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Cost")])]), _c('tr', [_c('th', [_vm._v("This Year")]), _c('th', [_vm._v("Last Year")]), _c('th', [_vm._v("%")]), _c('th', [_vm._v("This Year")]), _c('th', [_vm._v("Last Year")]), _c('th', [_vm._v("%")])])]), _c('tbody', [_vm._l(_vm.monthlySummaryData, function (monthData) {
    return _c('tr', {
      key: monthData.date
    }, [_c('td', [_vm._v(_vm._s(_vm._f("date")(monthData.date, 'MMMM')))]), _c('td', {
      staticClass: "text-right"
    }, [monthData.consumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.consumption, 0)))]) : _c('span', [_vm._v("N/A")])]), _c('td', {
      staticClass: "text-right"
    }, [monthData.prevConsumption !== null ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(monthData.prevConsumption, 0)))]) : _c('span', [_vm._v("N/A")])]), _c('td', [_c('PctDiff', {
      attrs: {
        "value": monthData.consumption,
        "prev-value": monthData.prevConsumption,
        "comparison-period": "year",
        "hide-text": ""
      }
    })], 1), _c('td', {
      staticClass: "text-right"
    }, [_vm._v("£" + _vm._s(_vm._f("numberFormat")(monthData.cost, 2)))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v("£" + _vm._s(_vm._f("numberFormat")(monthData.prevCost, 2)))]), _c('td', [_c('PctDiff', {
      attrs: {
        "value": monthData.cost,
        "prev-value": monthData.prevCost,
        "comparison-period": "year",
        "hide-text": ""
      }
    })], 1)]);
  }), _c('tr', [_c('th', [_vm._v("Total")]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.monthlySummaryData.reduce(function (acc, item) {
    return acc + item.consumption;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.monthlySummaryData.reduce(function (acc, item) {
    return acc + item.prevConsumption;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  }), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("£" + _vm._s(_vm._f("numberFormat")(_vm.monthlySummaryData.reduce(function (acc, item) {
    return acc + item.cost;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("£" + _vm._s(_vm._f("numberFormat")(_vm.monthlySummaryData.reduce(function (acc, item) {
    return acc + item.prevCost;
  }, 0), 2)))]), _c('th', {
    staticClass: "text-right"
  })])], 2)])])])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }